import './configs/main-config-dayjs';
import './configs/main-config-hammerjs';

import { enableProdMode, provideExperimentalZonelessChangeDetection, APP_ID, importProvidersFrom } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { AppComponent } from '../../../libs/interid-site-web/core-app/src/lib/components/app/app.component';
import { InteridSiteWebUiSharedModule } from '@interid/interid-site-web/ui-shared';
import { InteridSiteWebCoreAppModule } from '@interid/interid-site-web/core-app';
import { InteridSiteWebCoreHttpModule } from '@interid/interid-site-web/core-http';
import { InteridDataAccessModule } from '@interid/interid-site-data-access/common';
import { appRoutes } from './app/app.routes';
import { TranslateModule } from '@ngx-translate/core';
import { BrowserAnimationsModule, provideAnimations } from '@angular/platform-browser/animations';
import { CustomRouteReuseStrategy } from './app/router-strategy';
import { ROUTER_CONFIGURATION, RouteReuseStrategy, RouterModule } from '@angular/router';
import { environment } from './environments/environment';
import { RECAPTCHA_V3_SITE_KEY } from 'ng-recaptcha';
import { RouterConfiguration } from '@interid/interid-site-web/core';
import { provideClientHydration, withHttpTransferCacheOptions, BrowserModule, bootstrapApplication } from '@angular/platform-browser';

enableProdMode();

bootstrapApplication(AppComponent, {
    providers: [
        importProvidersFrom(BrowserModule, BrowserAnimationsModule, TranslateModule.forRoot(), RouterModule.forRoot(appRoutes, new RouterConfiguration()), InteridDataAccessModule.forRoot({
            injects: [],
            useFactory: () => ({
                apiEndpointUrl: environment.webApiEndpoint,
            }),
        }), InteridSiteWebCoreHttpModule, InteridSiteWebCoreAppModule, InteridSiteWebUiSharedModule),
        provideExperimentalZonelessChangeDetection(),
        provideClientHydration(withHttpTransferCacheOptions({
            includePostRequests: true
        })),
        RouterConfiguration,
        { provide: APP_ID, useValue: 'interid-web' },
        { provide: RECAPTCHA_V3_SITE_KEY, useValue: environment.recaptcha },
        { provide: ROUTER_CONFIGURATION, useExisting: RouterConfiguration },
        {
            provide: RouteReuseStrategy,
            useClass: CustomRouteReuseStrategy,
        },
        provideAnimations(),
    ]
})
    .catch((err) => console.error(err));
