import { ChangeDetectionStrategy, Component, HostListener, Inject, Injector, OnDestroy, OnInit, PLATFORM_ID, WritableSignal, inject, signal } from '@angular/core';
import { Subject } from 'rxjs';
import { distinctUntilChanged, filter, take, takeUntil } from 'rxjs/operators';
import { NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router, RouterOutlet } from '@angular/router';
import { ApiOnlineStatusService, AppBusEvent, AppBusService, AppLoadingService, EnvironmentWebService, GlobalBrowserScrollService, interidSiteWebI18n, JwtService, ViewBreakpointsService } from '@interid/interid-site-web/core';
import { connectToSignal, signalGetFrom } from '@interid/interid-site-web/ui-signals';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { isPlatformBrowser, NgClass } from '@angular/common';
import { EnvironmentWeb } from '@interid/interid-site-web/core';
import { AppSessionService } from '@interid/interid-site-web/core-session';
import { LoadingComponent } from '@interid/interid-site-web/ui-shared';

export const environment: EnvironmentWeb = {
    enableAngularDevelopmentMode: false,
    enableAngularRouterDebug: false,
    adminUrl: 'https://cms.interid.ru',
    webApiEndpoint: 'https://api.interid.ru',
    attachmentEndpoint: 'https://assets.interid.ru/uploaded',
    baseUrl: 'https://interid.ru',
    baseDomain: 'interid.ru',
    regionBaseDomain: 'interid.ru',
    baseDomainProtocol: 'https',
    enableSwitchCityDomain: true,
    enableSSRCache: true,
    ymId: 13196761,
    recaptcha: '6LeZojspAAAAAF6T-OTsJYXP6_TZ-qe1i0oKIcPU',
};

interface State {
    isAppOnline: boolean;
    isSignedIn: boolean;
}

@Component({
    selector: 'app-web-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [NgClass, TranslateModule, RouterOutlet, LoadingComponent],
})
export class AppComponent implements OnInit, OnDestroy {
    private readonly ngOnDestroy$: Subject<void> = new Subject<void>();
    private readonly injector = inject(Injector);
    private readonly router = inject(Router);
    protected appBus = inject(AppBusService);
    protected appLoading = inject(AppLoadingService);
    protected appOnlineStatusService = inject(ApiOnlineStatusService);
    protected jwtService = inject(JwtService);
    protected viewBreakpointsService = inject(ViewBreakpointsService);
    private readonly translate = inject(TranslateService);
    private readonly session = inject(AppSessionService);
    private readonly globalBrowserScroll = inject(GlobalBrowserScrollService);
    private readonly env = inject(EnvironmentWebService);

    constructor(
      @Inject(PLATFORM_ID) private platformId: Object
  ) {
    this.destroyInitialLoader();

        this.initEnv();
        this.initTranslateModule();
        this.initViewBreakpoints();
        this.initSession();
        this.initGlobalBrowserScroll();
    }

    private destroyInitialLoader(): void {
        if (isPlatformBrowser(this.platformId)) {
            this.router.events
                .pipe(
                    filter((e) => e instanceof NavigationEnd),
                    take(1)
                )
                .subscribe(() => {
                    if (window && window.document && window.document.getElementById) {
                        const initialLoader = window.document.getElementById('interidInitialLoader');

                        if (initialLoader && initialLoader.remove) {
                            initialLoader.remove();
                        }
                    }
                });
        }
    }



    private initSession(): void {
        this.session.init();
    }

    private initEnv(): void {
        this.env.current = environment;
    }

    private initTranslateModule(): void {
        this.translate.setDefaultLang('ru');
        this.translate.setTranslation('ru', interidSiteWebI18n, true);
    }

    private initViewBreakpoints(): void {
        this.viewBreakpointsService.watch();
    }

    private initGlobalBrowserScroll(): void {
        if (isPlatformBrowser(this.platformId) && document && document.body) {
            this.globalBrowserScroll.enabled$.subscribe((enabled) => {
                document.body.style.overflow = enabled ? 'auto' : 'hidden';
            });
        }
    }

    state: WritableSignal<State> = signal({
        isAppOnline: true,
        isSignedIn: false,
    });

    isLoading = signalGetFrom(this.appLoading.isLoading$, { requireSync: true });
    ngClass = signalGetFrom(this.viewBreakpointsService.ngClass$, { requireSync: true });

    ngOnInit(): void {
        connectToSignal(
            this.state,
            this.appOnlineStatusService.isUp$.pipe(distinctUntilChanged()),
            (state, onlineStatus) => ({ ...state, isAppOnline: onlineStatus }),
            this.injector
        );

        connectToSignal(
            this.state,
            this.jwtService.jwtVerified$,
            (state, isVerified) => (isVerified ? { ...state, isSignedIn: true } : { ...state, isSignedIn: false }),
            this.injector
        );

        this.router.events.pipe(takeUntil(this.ngOnDestroy$)).subscribe((event) => {
            if (event instanceof NavigationStart) {
                this.appLoading.addLoading();
            } else if (event instanceof NavigationCancel || event instanceof NavigationError || event instanceof NavigationEnd) {
                this.appLoading.completeAll();
            }
        });
    }

    ngOnDestroy(): void {
        this.ngOnDestroy$.next();
    }

    @HostListener('document:visibilitychange', [])
    visibilitychange() {
        if (window && !window.document.hidden) {
            this.appBus.emit({
                type: AppBusEvent.DocumentVisible,
            });
        }
    }
}
