import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InteridSiteWebUiSharedModule } from '@interid/interid-site-web/ui-shared';
import { InteridSiteWebUiSharedSearchModule } from '@interid/interid-site-web/ui-shared-search';
import { AppHeaderDesktopPhonesComponent } from './components/app-header-desktop-phones/app-header-desktop-phones.component';
import { AppHeaderDesktopCityComponent } from './components/app-header-desktop-city/app-header-desktop-city.component';
import { AppModalSelectCityService } from './components/app-modal-select-city/app-modal-select-city.service';
import { AppFooterMobileComponent } from './components/app-footer-mobile/app-footer-mobile.component';
import { AppHeaderMobileCatalogComponent } from './components/app-header-mobile/components/app-header-mobile-catalog/app-header-mobile-catalog.component';
import { AppLayoutComponent } from './components/app-layout/app-layout.component';
import { AppHeaderMobileComponent } from './components/app-header-mobile/app-header-mobile.component';
import { AppNotFoundComponent } from './components/app-not-found/app-not-found.component';
import { AppHeaderMobileMainComponent } from './components/app-header-mobile/components/app-header-mobile-main/app-header-mobile-main.component';
import { AppModalSelectCityComponent } from './components/app-modal-select-city/app-modal-select-city.component';
import { AppHeaderDesktopCategoriesComponent } from './components/app-header-desktop-categories/app-header-desktop-categories.component';
import { AppMessageComponent } from './components/app-message/app-message.component';
import { AppLayoutResolve } from './components/app-layout/app-layout.resolve';
import { TranslateModule } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';
import { AppHeaderWideComponent } from './components/app-header-wide/app-header-wide.component';
import { AppFooterWideComponent } from './components/app-footer-wide/app-footer-wide.component';
import { AppFloatbarMobileComponent } from './components/app-floatbar-mobile/app-floatbar-mobile.component';
import { InteridSiteWebUiSharedProductModule } from '@interid/interid-site-web/ui-shared-product';
import { InteridSiteWebUiSharedProductCardsModule } from '@interid/interid-site-web/ui-shared-product-cards';
import { AppMenuStateService } from './components/app-menu-state.service';
import { AppHeaderDesktopMenuComponent } from './components/app-header-desktop-menu/app-header-desktop-menu.component';

@NgModule({
    imports: [
        CommonModule,
        RouterModule.forChild([]),
        TranslateModule.forChild(),
        InteridSiteWebUiSharedModule,
        InteridSiteWebUiSharedSearchModule,
        InteridSiteWebUiSharedProductModule,
        InteridSiteWebUiSharedProductCardsModule,
    ],
    declarations: [ 
        AppLayoutComponent,
        AppFooterMobileComponent,
        AppFooterWideComponent,
        AppHeaderWideComponent,
        AppHeaderDesktopPhonesComponent,
        AppHeaderDesktopCityComponent,
        AppHeaderMobileComponent,
        AppFloatbarMobileComponent,
        AppHeaderMobileCatalogComponent,
        AppHeaderMobileMainComponent,
        AppModalSelectCityComponent,
        AppHeaderDesktopCategoriesComponent,
        AppHeaderDesktopMenuComponent,
        AppNotFoundComponent,
        AppMessageComponent
    ],
    providers: [
        AppLayoutResolve,
        AppModalSelectCityService,
        AppMenuStateService,
    ],
    exports: [ 
    ],
})
export class InteridSiteWebCoreAppModule {}
