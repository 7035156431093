import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AppSessionService } from '@interid/interid-site-web/core-session';
import { Notyf } from 'notyf';

export interface MessagesRequest {
    message: string;
    translate?: boolean;
    translateParams?: any;
    duration?: number;
}

@Injectable({
    providedIn: 'root',
})
export class MessagesService {
    private notyf: Notyf;
    private settings = {
        duration: 10000,
        ripple: false,
        dismissible: false,
    };

    constructor(readonly appSessionService: AppSessionService, private readonly translate: TranslateService) {
        if (typeof window !== 'undefined') {
            this.notyf = new Notyf({
                ...this.settings,
                position: {
                    x: 'right',
                    y: 'top',
                },
            });
        }
    }

    private showNotification(customHTML: string, settings, closeCallback: () => void, additionalClass: string = ''): void {
        if (!this.notyf) return;
        this.notyf.dismissAll();

        const notification = this.notyf.open({
            ...settings,
            message: this.getCustomNotificationTemplate(customHTML, additionalClass),
            position: {
                x: 'right',
                y: 'top',
            },
        });

        (window as any).closeNotification = () => {
            this.notyf.dismiss(notification);
            closeCallback();
        };
    }

    httpError(): void {
        this.error({
            message: 'Произошла ошибка. Проверьте ваше Интернет-соединение',
            translate: true,
        });
    }

    success(request: MessagesRequest): void {
        this.showMessage(request);
    }

    error(request: MessagesRequest): void {
        this.showMessage(request);
    }

    info(request: MessagesRequest): void {
        this.showMessage(request);
    }

    basketCompare(request: MessagesRequest, type: 'basket' | 'compare' | 'favorite', count): void {
        const message = request.translate ? this.translate.instant(request.message, request.translateParams) : request.message;

        this.showNotification(
            `
              <div class="c-n-message"><span>${message}</span></div>
    <a class="c-n-basket" style="${type === 'basket' ? 'display: flex' : 'display: none'}" href="/purchase/basket">
      <div class="c-n-count"><span>${count}</span></div>
      <img loading="lazy" src="/assets/images/u_shopping-cart.svg" /><span>Корзина</span>
    </a>
    <a class="c-n-compare" style="${type === 'compare' ? 'display: flex' : 'display: none'}" href="/compares">
      <div class="c-n-count"><span>${count}</span></div>
      <img loading="lazy" src="/assets/images/u_analytics.svg" /> <span>Сравнение</span>
    </a>
    <a class="c-n-favorite" style="${type === 'favorite' ? 'display: flex' : 'display: none'}" href="/favorites">
      <div class="c-n-count"><span>${count}</span></div>
      <img loading="lazy" src="/assets/images/u_heart-alt.svg" /> <span>Избранное</span>
    </a>
        `,
            this.settings,
            () => {},
            'c-n-flex-gap'
        );
    }

    cookie(request: MessagesRequest): void {
        const closeNotification = () => {
            document.cookie = 'cookiePolicyAccepted=true; path=/; max-age=' + 60 * 60 * 24 * 365;

            window.dispatchEvent(new CustomEvent('cookiePolicyAccepted'));
        };

        this.showNotification(
            `<div class="c-n-message">Продолжая использовать наш сайт, вы даете согласие на обработку файлов cookie, которые обеспечивают правильную работу сайта и соглашаетесь с нашей <a target="_blank" class="c-n-link" href="/privacy">Политикой конфиденциальности</a>.</div>`,
            { ...this.settings, duration: null },
            closeNotification
        );
    }

    warning(request: MessagesRequest): void {
        this.showMessage(request);
    }

    validationError(request: MessagesRequest): void {
        const message = request.translate ? this.translate.instant(request.message, request.translateParams) : request.message;
        this.showNotification(
            `
            <div class="c-n-message">
                <span>${message}</span>
            </div>
        `,
            this.settings,
            () => {}
        );
    }

    private showMessage(request: MessagesRequest): void {
        const message = request.translate ? this.translate.instant(request.message, request.translateParams) : request.message;
        this.showNotification(
            `
            <div class="c-n-message">
                <span>${message}</span>
            </div>
        `,
            this.settings,
            () => {}
        );
    }

    private getCustomNotificationTemplate(data: string, additionalClass: string = ''): string {
        return `
        <style>
.c-notyf {
  font-family: Roboto, sans-serif;
            font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;

	.c-n-data {
		display: flex;
		align-items: flex-start;
		justify-content: space-between;
		padding: 22px 15px 22px 35px;
		background: #ecf0f2;
		border-radius: 8px;
		box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
		width: 100%;
		margin: 8px;
        gap: 15px;
		max-width: 100%;
		min-width: 0;

        &.c-n-flex-gap {
                gap: 12px;

        @media only screen and (min-width: 768px) {
            gap: 24px;
        }
        @media only screen and (min-width: 1440px) {
            gap: 28px;
        }
        }

        .c-n-basket,
        .c-n-favorite,
        .c-n-compare {
            position: relative;
            flex-direction: column;
            align-items: center;
            gap: 5px;
            cursor: pointer;

            .c-n-count {
                width: 20px;
                height: 20px;
                background-color: #1269ff;
                border-radius: 50%;
                top: -7px;
                right: 7px;
                display: flex;
                justify-content: center;
                align-items: center;
                position: absolute;

                span {
                    color: #fff;
                    font-size: 12px; 
                }
            }

            span {
                color: #1269ff; 
            }
        }

        .c-n-compare,
        .c-n-favorite {
            .c-n-count {
                right: 15px;
            }
        } 
	}

	@media (min-width: 501px) {
		.c-n-data {
			max-width: 33vw;
			min-width: 344px;
		}
	}

	.c-n-message {
		display: inline-block;
		color: #253238; 

		.c-n-link {
			display: inline;
			color: #1269ff;
			text-decoration-line: underline;
		}
	}

	.c-n-close {
		cursor: pointer;
		display: flex;

		img {
			width: 14px;
			height: 14px;
		}
	}
}
        </style>
        <span class="c-notyf">
            <div class="c-n-data ${additionalClass}">
                ${data}
 
                <div class="c-n-close" onclick="window.closeNotification()">
                    <img src="/assets/images/Union.svg" loading="lazy" />
                </div>
            </div>
        </span>
    `;
    }
}
