import { NgModule } from '@angular/core';
import { ProductListComponent } from './components/product-list/product-list.component';
import { ProductListSortAndViewComponent } from './components/product-list-sort-and-view/product-list-sort-and-view.component';
import { ProductFiltersComponent } from './components/product-filters/product-filters.component';
import { ProductParamReferenceComponent } from './components/product-param-reference/product-param-reference.component';
import { ProductParamReferenceModalComponent } from './components/product-param-reference-modal/product-param-reference-modal.component';
import { ProductFiltersPreviewComponent } from './components/product-filters-preview/product-filters-preview.component';
import { InteridSiteWebUiSharedModule } from '@interid/interid-site-web/ui-shared';
import { InteridSiteWebUiSharedContentModule } from '@interid/interid-site-web/ui-shared-content';
import { InteridSiteWebUiSharedProductCardsModule } from '@interid/interid-site-web/ui-shared-product-cards';
import { CatalogCategorySeoLinksComponent } from './components/catalog-category-seo-links/catalog-category-seo-links.component';
import { ProductFiltersMobileLvl1Component } from './components/product-filters/components/product-filters-mobile-lvl1/product-filters-mobile-lvl1.component';
import { ProductFiltersMobileLvl2Component } from './components/product-filters/components/product-filters-mobile-lvl2/product-filters-mobile-lvl2.component';
import { QuotationRequestModalComponent } from './components/quotation-request-modal/quotation-request-modal.component';
import { QuotationRequestComponent } from './components/quotation-request/quotation-request.component';
import { ProductDetailsQuotationComponent } from './components/product-details-quotation/product-details-quotation.component';
import { ProductFiltersSliderComponent } from './components/product-filters/components/product-filters-slider/product-filters-slider';
import { ProductManufacturerDealerComponent } from './components/product-manufactuter-dealer/product-manufactuter-dealer.component';

const PRODUCT_COMPONENTS_SHARED = [
    ProductListComponent,
    ProductListSortAndViewComponent,
    ProductManufacturerDealerComponent,
    ProductFiltersMobileLvl1Component,
    ProductFiltersMobileLvl2Component,
    ProductFiltersComponent,
    ProductParamReferenceComponent,
    ProductParamReferenceModalComponent,
    ProductFiltersPreviewComponent,
    CatalogCategorySeoLinksComponent,
    QuotationRequestComponent,
    QuotationRequestModalComponent,
    ProductDetailsQuotationComponent,
    ProductFiltersSliderComponent,
];


@NgModule({
    imports: [
        InteridSiteWebUiSharedModule,
        InteridSiteWebUiSharedContentModule,
        InteridSiteWebUiSharedProductCardsModule
    ],
    declarations: [
        ...PRODUCT_COMPONENTS_SHARED,
    ],
    exports: [
        ...PRODUCT_COMPONENTS_SHARED,
    ],
})
export class InteridSiteWebUiSharedProductModule {
}

