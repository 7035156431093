import { ChangeDetectionStrategy, Component, inject, signal, viewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { retry, retryWhen, takeUntil } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { AppShared, AuthResponse } from '@interid/interid-site-shared';

import App = AppShared.App;
import { UIInputComponent } from '@interid/interid-site-web/ui-shared';
import { InteridWebAccountDataAccess } from '@interid/interid-site-data-access/web';
import { ApiErrorHandlerService, defaultModalConfig, genericRetryStrategy, MessagesService } from '@interid/interid-site-web/core';
import { AuthSignInComponent } from '../auth-sign-in/auth-sign-in.component';
import { ReCaptchaV3Service } from 'ng-recaptcha';

interface FormValue {
    email: string;
}

interface State {
    form: UntypedFormGroup;
}

interface ModalResponse {
    accountResponse: AuthResponse;
}

@Component({
    templateUrl: './auth-forgot-password.component.html',
    styleUrls: ['./auth-forgot-password.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AuthForgotPasswordComponent {
    private readonly fb = inject(UntypedFormBuilder);
    private readonly matDialog = inject(MatDialog)
    private readonly matDialogRef = inject(MatDialogRef<AuthForgotPasswordComponent, ModalResponse>);
    private readonly endpoint = inject(InteridWebAccountDataAccess);
    private readonly errorHandler = inject(ApiErrorHandlerService);
    private readonly messages = inject(MessagesService);
    private readonly recaptchaV3Service = inject(ReCaptchaV3Service);

    public emailRef = viewChild<UIInputComponent>('email');

    private nextSubmit$: Subject<void> = new Subject<void>();

    public state = signal<State>({
        form: this.fb.group({
            email: ['', [Validators.required, Validators.email]],
        }),
    });

    t(input: string): string {
        return `auth.shared.components.auth_forgot_password.${input}`;
    }

    get formValue(): FormValue {
        return this.state().form.value;
    }

    focusEmail(): void {
        setTimeout(() => {
            if (this.emailRef) {
                this.emailRef().focusInput();
            }
        });
    }

    signIn(): void {
        this.matDialogRef.close();

        this.matDialog.open(AuthSignInComponent, {
            ...defaultModalConfig,
        });
    }

    close(): void {
        this.matDialogRef.close();
    }

    ngSubmit(): void {
        this.nextSubmit$.next();

        this.recaptchaV3Service.execute('ForgotPassword').pipe(
            takeUntil(this.nextSubmit$),
        ).subscribe((recaptchaV3Token) => this.submitForm(recaptchaV3Token));
    }

    submitForm(recaptchaV3Token: string): void {
        if (! this.state().form.valid) {
            this.state().form.markAllAsTouched();

            return;
        }

        const observable = this.endpoint.sendResetPasswordLink({
            app: App.Site,
            email: this.formValue.email,
        }, recaptchaV3Token);

        this.state().form.disable();

        observable.pipe(
            retryWhen(genericRetryStrategy({
                retryDuration: 1000,
                excludedStatusCodes: [401, 404],
            })),
            takeUntil(this.nextSubmit$)
        ).subscribe({
            next: () => {
                this.messages.success({
                    message: this.t('success'),
                    translate: true,
                });

                this.close();
            },
            error: (error: HttpErrorResponse) => {
                this.errorHandler.handle(error);

                this.state().form.enable();

                this.focusEmail();
            }
        });
    }
}
