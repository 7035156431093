import { ChangeDetectionStrategy, Component, input, Input } from '@angular/core';

@Component({
    selector: 'app-shared-loading',
    templateUrl: './loading.component.html',
    styleUrls: [
        './loading.component.scss',
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
})
export class LoadingComponent {
    public loading = input(false);
    public withBlocker = input(false);
}
